<template>
  <!-- PROCESSING -->
  <a-result v-if="result === 'processing'">
    <template #icon>
      <a-icon type="loading" />
    </template>
    <h1 slot="title">
      {{ $t('msg.shop.callback.processing.title') }}
    </h1>
    <div slot="subTitle">
      <h4 class="text-secondary p-2">
        {{ $t('msg.shop.callback.processing.subtitle') }}
      </h4>
    </div>
  </a-result>
  <!-- SUCCESS -->
  <a-result status="success" v-else-if="result === 'success'">
    <h1 slot="title">
      {{ $t('msg.shop.callback.success.title') }}
    </h1>
    <div slot="subTitle">
      <h4 class="text-secondary p-2">
        {{ $t('msg.shop.callback.success.subtitle') }}
      </h4>
    </div>
    <template #extra>
      <router-link to="/shops">
        <a-button
          type="primary"
          size="large">
          {{ $t('msg.shop.callback.success.action') }}
        </a-button>
      </router-link>
    </template>
  </a-result>
  <!-- FAILURE -->
  <a-result status="error" v-else-if="result === 'failure'">
    <h1 slot="title">
      {{ $t('msg.shop.callback.failure.title') }}
    </h1>
    <div slot="subTitle">
      <h4 class="text-secondary p-2">
        {{ $t('msg.shop.callback.failure.subtitle') }}
      </h4>
    </div>
    <template #extra>
      <router-link :to="`/shops/connect?type=${type}`">
        <a-button
          type="primary"
          size="large">
          {{ $t('msg.shop.callback.failure.action1') }}
        </a-button>
      </router-link>
      <router-link to="/shops">
        <a-button
          size="large">
          {{ $t('msg.shop.callback.failure.action2') }}
        </a-button>
      </router-link>
    </template>
  </a-result>
</template>

<script>
import lms from '@/api/lms'
export default {
  name: 'ShopCallback',
  data: function () {
    return {
      result: 'processing',
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  mounted() {
    const payload = { ...this.$route.query, account: this.account }
    lms.platform.install(this.type, payload)
      .then(_ => (this.result = 'success'))
      .catch(_ => (this.result = 'failure'))
  },
}
</script>
